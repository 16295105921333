import { gql } from '@apollo/client';
import { businessFragments } from './businessQueries';
import { fragments } from 'graphql/queries';

export const upsertProject = gql`
  mutation ($input: ProjectInput!) {
    upsertProject(input: $input) {
      ...Project
    }
  }
  ${businessFragments.project}
`;

export const deleteProject = gql`
  mutation ($id: String!) {
    deleteProject(id: $id)
  }
`;

export const addCreatorToProject = gql`
  mutation ($projectId: String!, $creatorId: String!) {
    addCreatorToProject(projectId: $projectId, creatorId: $creatorId) {
      ...Project
    }
  }
  ${businessFragments.project}
`;

export const deleteCreatorFromProject = gql`
  mutation ($projectId: String!, $creatorId: String!) {
    deleteCreatorFromProject(projectId: $projectId, creatorId: $creatorId) {
      ...Project
    }
  }
  ${businessFragments.project}
`;

export const createBrief = gql`
  mutation (
    $projectId: String!
    $paymentModel: ClientPaymentModel!
    $state: BriefState!
    $main: BriefMainInput!
    $concept: BriefConceptInput
    $deliverables: BriefDeliverablesInput
    $ugc: BriefUgcInput
    $creators: BriefCreatorsInput
    $copy: BriefCopyInput
    $creative: BriefCreativeInput
  ) {
    createBrief(
      projectId: $projectId
      paymentModel: $paymentModel
      state: $state
      main: $main
      concept: $concept
      deliverables: $deliverables
      ugc: $ugc
      creators: $creators
      copy: $copy
      creative: $creative
    ) {
      ...Brief
    }
  }
  ${businessFragments.brief}
  ${businessFragments.briefMain}
  ${businessFragments.briefConcept}
  ${businessFragments.briefConceptMaterial}
  ${businessFragments.briefDeliverables}
  ${businessFragments.briefUgc}
  ${businessFragments.briefCreators}
  ${businessFragments.briefCopy}
  ${businessFragments.briefCreative}
  ${businessFragments.briefComment}
`;

export const updateBrief = gql`
  mutation (
    $id: String!
    $state: BriefState
    $main: BriefMainInput
    $concept: BriefConceptInput
    $deliverables: BriefDeliverablesInput
    $ugc: BriefUgcInput
    $creators: BriefCreatorsInput
    $copy: BriefCopyInput
    $creative: BriefCreativeInput
    $comments: [BriefCommentInput!]
  ) {
    updateBrief(
      id: $id
      state: $state
      main: $main
      concept: $concept
      deliverables: $deliverables
      ugc: $ugc
      creators: $creators
      copy: $copy
      creative: $creative
      comments: $comments
    ) {
      ...Brief
    }
  }
  ${businessFragments.brief}
  ${businessFragments.briefMain}
  ${businessFragments.briefConcept}
  ${businessFragments.briefConceptMaterial}
  ${businessFragments.briefDeliverables}
  ${businessFragments.briefUgc}
  ${businessFragments.briefCreators}
  ${businessFragments.briefCopy}
  ${businessFragments.briefCreative}
  ${businessFragments.briefComment}
`;

export const createAd = gql`
  mutation ($projectId: String!, $type: AdType!, $title: String!, $medias: [AdMediaInput!]!) {
    createAd(projectId: $projectId, type: $type, title: $title, medias: $medias) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const createAds = gql`
  mutation ($projectId: String!, $inputs: [AdInput!]!) {
    createAds(projectId: $projectId, inputs: $inputs) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const createAdsFromMedia = gql`
  mutation ($projectId: String!, $type: AdType!, $medias: [AdMediaInput!]!) {
    createAdsFromMedia(projectId: $projectId, type: $type, medias: $medias) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const updateAd = gql`
  mutation ($id: String!, $link: String!, $state: AdState, $title: String, $medias: [AdMediaInput!]) {
    updateAd(id: $id, link: $link, state: $state, title: $title, medias: $medias) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const deleteAd = gql`
  mutation ($id: String!) {
    deleteAd(id: $id)
  }
`;

export const unarchiveAd = gql`
  mutation ($id: String!) {
    unarchiveAd(id: $id)
  }
`;

export const addAdComment = gql`
  mutation (
    $adId: String!
    $comment: String!
    $parentId: String
    $link: String!
    $internalState: Boolean!
    $mentionedUserIds: [String!]
  ) {
    addAdComment(
      adId: $adId
      comment: $comment
      parentId: $parentId
      link: $link
      internalState: $internalState
      mentionedUserIds: $mentionedUserIds
    ) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const updateAdComment = gql`
  mutation ($adId: String!, $orderNumber: Int!, $comment: String!) {
    updateAdComment(adId: $adId, orderNumber: $orderNumber, comment: $comment) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const deleteAdComment = gql`
  mutation ($adId: String!, $commentId: String!) {
    deleteAdComment(adId: $adId, commentId: $commentId) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const addAdVideoMarker = gql`
  mutation ($adId: String!, $versionId: String!, $imageKitName: String!, $marker: AdMediaMarkerInput!) {
    addAdVideoMarker(adId: $adId, versionId: $versionId, imageKitName: $imageKitName, marker: $marker) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const addAdImageCanvas = gql`
  mutation ($adId: String!, $versionId: String!, $imageKitName: String!, $canvas: String!) {
    addAdImageCanvas(adId: $adId, versionId: $versionId, imageKitName: $imageKitName, canvas: $canvas) {
      ...Ad
    }
  }
  ${businessFragments.ad}
  ${businessFragments.adVersion}
  ${businessFragments.adMedia}
  ${businessFragments.adMediaMarker}
  ${businessFragments.adComment}
  ${businessFragments.adHistoryEvent}
`;

export const uploadMediaFile = gql`
  mutation ($base64: String!, $fileName: String!) {
    uploadMediaFile(base64: $base64, fileName: $fileName) {
      ...ImageKitFileDetails
    }
  }
  ${businessFragments.imageKitFileDetails}
`;

export const uploadMediaFiles = gql`
  mutation ($input: [MediaUploadInput!]!) {
    uploadMediaFiles(input: $input) {
      ...ImageKitFileDetails
    }
  }
  ${businessFragments.imageKitFileDetails}
`;

export const moveAllApprovedAdsToLaunched = gql`
  mutation ($projectId: String!, $domain: String!) {
    moveAllApprovedAdsToLaunched(projectId: $projectId, domain: $domain)
  }
`;

export const updateAdDna = gql`
  mutation ($input: AdDnaInput!) {
    updateAdDna(input: $input) {
      ...AdDna
    }
  }
  ${businessFragments.adDna}
  ${businessFragments.adDnaRole}
  ${fragments.clientPersona}
`;

export const updateAdDnas = gql`
  mutation ($ids: [String!]!, $request: AdDnaUpdateRequest!) {
    updateAdDnas(ids: $ids, request: $request) {
      ...AdDna
    }
  }
  ${businessFragments.adDna}
  ${businessFragments.adDnaRole}
  ${fragments.clientPersona}
`;

export const enhanceAdDnaViaAi = gql`
  mutation ($id: String!) {
    enhanceAdDnaViaAi(id: $id) {
      ...AdDna
    }
  }
  ${businessFragments.adDna}
  ${businessFragments.adDnaRole}
  ${fragments.clientPersona}
`;

export const updateInsights = gql`
  mutation ($clientId: String!) {
    updateInsights(clientId: $clientId)
  }
`;

export const initChatGptSession = gql`
  mutation ($searchCriteria: AdDnaSearchCriteria!) {
    initChatGptSession(searchCriteria: $searchCriteria)
  }
`;

export const askChatGpt = gql`
  mutation ($adDnas: [AdDnaInput!]!, $question: String!) {
    askChatGpt(adDnas: $adDnas, question: $question)
  }
`;
