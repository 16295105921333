import React, { useCallback } from 'react';
import clsx from 'classnames';
import Checkbox from 'components/common/checkbox';
import { Props } from 'components/common/dropdownMenu/types';
import styles from './DropdownMenu.module.scss';
import { makeId } from 'utils/stringUtils';
import { TooltipWrapper } from 'components/common';
import { ReactComponent as CheckIcon } from 'assets/img/checkSelected.svg';
import { ReactComponent as CheckLightGreenIcon } from 'assets/img/checkSelectedLightGreen.svg';

const DropDownMenu = ({
  variants,
  chosenValue,
  chooseValue,
  multiSelect,
  open,
  containerClassname,
  darkMode = false,
  ...rest
}: Props): JSX.Element => {
  const fieldStyle = useCallback((chosen: boolean) => {
    return clsx(styles.field, {
      [styles.chosen]: chosen,
    });
  }, []);

  return (
    <>
      {open && (
        <div className={clsx(styles.container, containerClassname, { [styles.containerDarkMode]: darkMode })} {...rest}>
          {variants.map((value) => {
            const checkbox = (
              <Checkbox
                readOnly
                className={styles.checkbox}
                label={value.name}
                alignLabel="right"
                checked={value.selected}
              />
            );
            const chosen = value.selected || chosenValue === value.id;

            return (
              <div
                key={value.id}
                className={fieldStyle(chosen)}
                onClick={() => {
                  chooseValue(value);
                }}
              >
                {multiSelect ? (
                  <>
                    {!!value.tooltip?.length ? (
                      <TooltipWrapper id={makeId()} place="bottom" msg={value.tooltip} content={checkbox} />
                    ) : (
                      checkbox
                    )}
                  </>
                ) : (
                  <div className={styles.value}>
                    {value.icon && <div className={styles.icon}>{value.icon}</div>}
                    {!!value.tooltip?.length ? (
                      <TooltipWrapper
                        id={makeId()}
                        place="bottom"
                        msg={value.tooltip}
                        content={
                          <div className={styles.valueContent}>
                            <div>{value.name}</div>
                            {chosen && (
                              <>
                                {darkMode ? (
                                  <CheckLightGreenIcon className={styles.chosenIcon} />
                                ) : (
                                  <CheckIcon className={styles.chosenIcon} />
                                )}
                              </>
                            )}
                          </div>
                        }
                      />
                    ) : (
                      <div className={styles.valueContent}>
                        <div>{value.name}</div>
                        {chosen && (
                          <>
                            {darkMode ? (
                              <CheckLightGreenIcon className={styles.chosenIcon} />
                            ) : (
                              <CheckIcon className={styles.chosenIcon} />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DropDownMenu;
