import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Ad, AdMedia, AdType, IMAGE_KIT_TEMP_FOLDER, ImageKitFileDetails } from 'types/businessTypes.d';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { adTitleMaxLength, getAssetRatio, getImageKitPath, greatestCommonDivisor } from 'utils/businessUtils';
import { black_500 } from 'utils/styleUtils';
import logger from 'utils/logger/logger';
import { ContentType, findContentType } from 'types/contentTypes';
import { Button, Input, Loading, RadioButton } from 'components/common';
import { createAds, updateAd } from 'graphql/businessMutations';
import { ReactComponent as UploadIcon } from 'assets/img/uploadFile.svg';
import { IKContext, IKImage, IKVideo } from 'imagekitio-react';
import { useTrackedSelector } from 'components/app/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DDType, DraggableItem } from './DraggableItem';
import ContentItem from './ContentItem';
import { uploadToImageKit } from 'services/imagekit.service';
import { authImageKit } from 'services/auth.service';
import ToasterInfo from 'components/common/toasterInfo';
import { AD_VIEW, CLIENT_ID, PROJECT_ID } from 'utils/routingUtils';
import { getDomain } from 'utils/windowUtils';
import styles from './CreateAddModalWithSizes.module.scss';
import { getContactUsMessage } from 'utils/i18nUtils';
import clsx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/delete.svg';
import { ReactComponent as DangerIcon } from 'assets/img/danger.svg';

const acceptStyle = {
  borderColor: black_500,
};

const maxFiles = 10;

interface AdValues {
  title: string;
  type: AdType;
  medias: AdMedia[];
}

const emptyAdValues: AdValues = {
  title: 'New ad',
  type: AdType.MULTI_SIZE_IMAGE,
  medias: [],
};

interface Props {
  clientId: string;
  projectId: string;
  onHide: () => void;
  onDone: () => void;
  ad?: Ad;
}

export default function CreateAdModalWithSizes({ clientId, projectId, onHide, onDone, ad }: Props): JSX.Element {
  const { t } = useTranslation();

  const [doCreateAds] = useMutation<{ createAds?: Ad[] }>(createAds);
  const [doUpdate] = useMutation<{ updateAd?: Ad }>(updateAd);

  const {
    config: { imagekitUrl, imagekitPublicKey, imagekitAuthEndpoint },
  } = useTrackedSelector();

  const [activeIndex, setActiveIndex] = useState(0);
  const [ads, setAds] = useState<AdValues[]>(
    !!ad?.versions?.length
      ? [
          {
            title: ad.title,
            type: ad.type,
            medias: ad.versions[0].medias,
          },
        ]
      : [emptyAdValues],
  );
  const [loading, setLoading] = useState(false);

  const getPathToAd = useCallback(
    (adId: string) => `/${AD_VIEW}/${adId}?${CLIENT_ID}=${clientId}&${PROJECT_ID}=${projectId}`,
    [clientId, projectId],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const getMediaPath = useCallback(
    (fileName: string, isNew: boolean) => {
      return ad && !isNew
        ? getImageKitPath({
            clientId,
            projectId,
            adId: ad.id,
            versionId: ad.versions[0].id,
            fileName,
          })
        : `${IMAGE_KIT_TEMP_FOLDER}/${fileName}`;
    },
    [ad, clientId, projectId],
  );

  const handleSubmit = useCallback(() => {
    setLoading(true);

    const createMultipleAds = () => {
      const variables = {
        projectId,
        inputs: ads.map(({ title, medias }) => ({
          title,
          type: medias[0].type === 'image' ? AdType.MULTI_SIZE_IMAGE : AdType.MULTI_SIZE_VIDEO,
          medias: medias.map(({ imageKitFileId, imageKitName, name, type, size, mime, height, width, ratio }) => ({
            imageKitFileId,
            imageKitName,
            name,
            type,
            size,
            mime,
            height,
            width,
            ratio,
          })),
        })),
      };
      doCreateAds({ variables })
        .then((result) => {
          if (result?.data) {
            setLoading(false);
            showSuccessNotification(t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.CREATE_SUCCESS'));
            onDone();
          } else {
            setLoading(false);
            logger.error('No result for ad add');
            showErrorNotification();
          }
        })
        .catch((error) => {
          setLoading(false);
          logger.error(`Add ad error: ${error}`);
          showErrorNotification();
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateAd = (title: string, medias: AdMedia[]) => {
      if (!ad) return;

      const variables = {
        id: ad.id,
        link: `${getDomain()}/#${getPathToAd(ad.id)}`,
        title,
        medias: medias.map(({ imageKitFileId, imageKitName, name, type, size, mime, height, width, ratio }) => ({
          imageKitFileId,
          imageKitName,
          name,
          type,
          size,
          mime,
          height,
          width,
          ratio,
        })),
      };
      doUpdate({ variables })
        .then((result) => {
          if (result?.data) {
            setLoading(false);
            showSuccessNotification(t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.UPDATE_SUCCESS'));
            onDone();
          } else {
            setLoading(false);
            logger.error('No result for ad add');
            showErrorNotification();
          }
        })
        .catch((error) => {
          setLoading(false);
          logger.error(`Add ad error: ${error}`);
          showErrorNotification();
        });
    };

    if (ad) {
      // updateAd();
    } else {
      createMultipleAds();
    }
  }, [
    ad,
    ads,
    doCreateAds,
    doUpdate,
    getPathToAd,
    onDone,
    projectId,
    showErrorNotification,
    showSuccessNotification,
    t,
  ]);

  const onMediaUploadedToImageKit = useCallback(
    (data: ImageKitFileDetails[]) => {
      const newMedias = data.map((details) => {
        return {
          imageKitFileId: details.fileId,
          imageKitName: details.name,
          name: details.name.substring(0, details.name.lastIndexOf('.')), // cut extension off
          type: details.fileType,
          size: details.size,
          mime: details.mime
            ? details.mime
            : findContentType(details.name.substring(details.name.lastIndexOf('.') + 1)),
          height: details.height,
          width: details.width,
          ratio: getAssetRatio(details.width, details.height),
          isNew: true,
        } as AdMedia;
      });

      setAds((prev) => {
        const newArray = cloneDeep(prev);
        const medias = newArray[activeIndex].medias;

        if (ad) {
          // replace the image
          newArray[activeIndex].medias = newMedias.length === 1 ? [newMedias[0]] : medias;
        } else {
          newArray[activeIndex].medias = [...medias, ...newMedias];
        }

        return newArray;
      });
    },
    [activeIndex, ad],
  );

  // D&D and file selection

  // upload all files at once
  const uploadFiles = useCallback(
    async (files: { base64: string; fileName: string }[]) => {
      // direct upload from client to ImageKit
      try {
        const result: ImageKitFileDetails[] = [];
        for (const file of files) {
          const auth = await authImageKit();
          if (auth) {
            const uploaded = await uploadToImageKit({
              file: file.base64,
              fileName: file.fileName,
              folder: IMAGE_KIT_TEMP_FOLDER,
              useUniqueFileName: true,
              publicKey: imagekitPublicKey,
              signature: auth.signature,
              expire: auth.expire,
              token: auth.token,
            });

            if (uploaded) {
              result.push(uploaded);
            } else {
              logger.error(`Upload media error`);
            }
          }
        }
        onMediaUploadedToImageKit(result);
      } catch (e) {
        logger.error(`Upload medias error: ${e}`);
        showErrorNotification();
      } finally {
        setLoading(false);
      }
    },
    [imagekitPublicKey, onMediaUploadedToImageKit, showErrorNotification],
  );

  const onDrop = useCallback(
    (acceptedFiles: Blob[]) => {
      const files: { base64: string; fileName: string }[] = [];
      acceptedFiles.forEach((file: Blob, index) => {
        const reader = new FileReader();
        reader.onerror = () => showErrorNotification();
        reader.onload = ((theFile) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (e: any) => {
            const content = e.target.result;
            setLoading(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (content) files.push({ base64: content, fileName: theFile.name });
            if (index === acceptedFiles.length - 1) uploadFiles(files);
          };
        })(file);
        reader.readAsDataURL(file);
      });
    },
    [showErrorNotification, uploadFiles],
  );

  const handleAdDelete = useCallback(
    (indexToDelete: number) => {
      if (activeIndex >= indexToDelete && activeIndex !== 0) {
        setActiveIndex((prev) => prev - 1);
      }

      setAds((prev) => {
        const newArray = cloneDeep(prev);
        return newArray.filter((i, index) => index !== indexToDelete);
      });
    },
    [activeIndex],
  );

  const handleMediaDelete = useCallback(
    (indexToDelete: number) => {
      setAds((prev) => {
        const newArray = cloneDeep(prev);

        const medias = cloneDeep(newArray[activeIndex].medias);
        newArray[activeIndex].medias = medias.filter((i, index) => index !== indexToDelete);

        return newArray;
      });
    },
    [activeIndex],
  );

  const handleMediaNameChange = useCallback(
    (value: string, index: number) => {
      setAds((prev) => {
        const newArray = cloneDeep(prev);

        // new array required so that react update happens
        const medias = cloneDeep(newArray[activeIndex].medias);
        medias[index].name = value;

        newArray[activeIndex].medias = medias;
        return newArray;
      });
    },
    [activeIndex],
  );

  const handleMediasShuffle = useCallback(
    (currentIndex: number, newIndex: number) => {
      if (currentIndex === newIndex || currentIndex === newIndex - 1) return;

      setAds((prev) => {
        const newArray = cloneDeep(prev);

        const medias = cloneDeep(newArray[activeIndex].medias);
        medias.splice(newIndex, 0, medias.splice(currentIndex, 1)[0]);

        newArray[activeIndex].medias = medias;
        return newArray;
      });
    },
    [activeIndex],
  );

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: maxFiles,
    maxSize: 100 * 1000000, // imagekit max file size is 25MB in the free package
    onDrop,
    accept: {
      [`${ContentType.IMAGE_PNG}`]: ['.png'],
      [`${ContentType.IMAGE_JPG}`]: ['.jpg'],
      [`${ContentType.IMAGE_JPEG}`]: ['.jpeg'],
      [`${ContentType.IMAGE_GIF}`]: ['.gif'],
      [`${ContentType.IMAGE_WEBP}`]: ['.webp'],
      [`${ContentType.IMAGE_SVG_XML}`]: ['.svg'],
      [`${ContentType.VIDEO_MPEG}`]: ['.mpeg'],
      [`${ContentType.VIDEO_MP4}`]: ['.mp4'],
      [`${ContentType.VIDEO_MOV}`]: ['.mov'],
    },
  });

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragAccept],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rootProps = useMemo(
    () =>
      getRootProps({
        className: !!ads[activeIndex].medias.length ? styles.dropzoneWithMedias : styles.dropzone,
        style,
      }),
    [ads, activeIndex, getRootProps, style],
  );

  const dropZoneComponent = useMemo(
    () => (
      <div
        {...rootProps}
        style={{
          pointerEvents: ads[activeIndex].medias.length === maxFiles ? 'none' : undefined,
        }}
      >
        <input {...getInputProps()} />
        <div>
          <UploadIcon />
        </div>
        <div className={styles.upload}>{t('COMMON.UPLOAD_FILES')}</div>
        <div className={styles.dragAndDrop}>
          <div className={styles.text1}>{t('COMMON.CLICK_TO_BROWSE_1')}</div>
          <Button
            buttonType="tertiary"
            onClick={open}
            type="button"
            className={styles.browse}
            disabled={ads[activeIndex].medias.length === maxFiles}
          >
            {t('COMMON.CLICK_TO_BROWSE_2')}
          </Button>
        </div>
        <div className={styles.text2}>{t('COMMON.CLICK_TO_BROWSE_3')}</div>
      </div>
    ),
    [activeIndex, ads, getInputProps, open, rootProps, t],
  );

  const duplicatedRatio = useMemo(() => {
    const medias = cloneDeep(ads[activeIndex].medias);
    const duplicatedRatios = medias
      .map((i) => getAssetRatio(i.width, i.height))
      .filter((e, i, a) => a.indexOf(e) !== i);
    return !!duplicatedRatios.length ? duplicatedRatios[0] : null;
  }, [activeIndex, ads]);

  return (
    <IKContext urlEndpoint={imagekitUrl} publicKey={imagekitPublicKey} authenticationEndpoint={imagekitAuthEndpoint}>
      <Modal open={true} onCancel={onHide} className={styles.modal} footer={null} maskClosable={false} width={1100}>
        <div className={styles.modalHeader}>
          <div className={styles.title}>
            {!ad
              ? t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.ADD')
              : t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.EDIT')}
          </div>
        </div>
        <div className={styles.body}>
          {loading && (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          )}

          <div className={styles.workingSection}>
            <div className={styles.start}>
              <div>
                <div className={styles.startItems}>
                  {ads.map(({ title }, index) => (
                    <div key={index} className={clsx(styles.item, { [styles.itemActive]: activeIndex === index })}>
                      <div className={styles.itemText} onClick={() => setActiveIndex(index)}>
                        {!!title.length ? title : t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.TITLE_PLACEHOLDER')}
                      </div>
                      {ads.length > 1 && (
                        <DeleteIcon className={styles.deleteItem} onClick={() => handleAdDelete(index)} />
                      )}
                    </div>
                  ))}
                </div>
                {!!ads[activeIndex].title.length && (
                  <Button
                    type="button"
                    buttonType="tertiary"
                    icon={<PlusIcon />}
                    size="normal"
                    onClick={() => {
                      const currentCount = ads.length;
                      setAds((prev) => {
                        const newArray = cloneDeep(prev);
                        return [...newArray, emptyAdValues];
                      });
                      setActiveIndex(currentCount);
                    }}
                  >
                    {t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.ADD_NEW_AD')}
                  </Button>
                )}
              </div>
              {!!ads[activeIndex].medias.length && dropZoneComponent}
            </div>
            <div className={styles.end}>
              {!ad && (
                <div className={styles.formItem}>
                  <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.TYPE')}</div>
                  <div className={styles.formItemType}>
                    {[
                      { type: AdType.MULTI_SIZE_IMAGE, label: t('AD_TYPE.SINGLE_AD') },
                      { type: AdType.CAROUSEL, label: t('AD_TYPE.CAROUSEL') },
                    ].map(({ type, label }) => (
                      <RadioButton
                        key={type}
                        name="type"
                        label={label}
                        alignLabel="right"
                        value={type}
                        onChange={(e) => {
                          setAds((prev) => {
                            const newArray = cloneDeep(prev);
                            newArray[activeIndex].type = e.target.value as AdType;
                            return newArray;
                          });
                        }}
                        checked={ads[activeIndex].type === type}
                      />
                    ))}
                  </div>
                </div>
              )}

              <div className={styles.formItem}>
                <Input
                  type="title"
                  name="title"
                  onChange={(e) => {
                    setAds((prev) => {
                      const newArray = cloneDeep(prev);
                      newArray[activeIndex].title = e.target.value.trim();
                      return newArray;
                    });
                  }}
                  value={ads[activeIndex].title}
                  maxLength={adTitleMaxLength}
                />
              </div>

              <div className={styles.maxFiles}>
                <span>
                  {ads[activeIndex].type === AdType.MULTI_SIZE_IMAGE ||
                  ads[activeIndex].type === AdType.MULTI_SIZE_VIDEO
                    ? t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.MULTI_SIZE_FILES')
                    : t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.CAROUSEL_FILES')}
                </span>
                <span>{t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.MAX_FILES_COUNT_1')}</span>
                <span style={{ fontWeight: '700' }}>
                  {t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.MAX_FILES_COUNT_2', {
                    max: maxFiles,
                  })}
                </span>
              </div>

              {duplicatedRatio && [AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(ads[activeIndex].type) && (
                <div className={styles.sameRatio}>
                  <DangerIcon />
                  <span className={styles.sameRatioText}>
                    {t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.SAME_RATIOS')}
                  </span>
                </div>
              )}

              <div className={styles.mainSection}>
                {/* d&d and file selector */}
                {!ads[activeIndex].medias.length && dropZoneComponent}

                {!!ads[activeIndex].medias.length && ads[activeIndex].type === AdType.CAROUSEL && (
                  <div className={styles.carouselMediasWrapper}>
                    <div className={styles.slides}>
                      <div>
                        {ads[activeIndex].medias.map((media, index) => (
                          <div key={index}>
                            <div className={styles.slide}>{`${t(
                              'PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.SLIDE',
                            )} ${index + 1}`}</div>
                            <div className={styles.separator} />
                          </div>
                        ))}
                      </div>
                    </div>

                    <DndProvider backend={HTML5Backend}>
                      <div className={styles.medias}>
                        {ads[activeIndex].medias.map((media, index) => {
                          return ads[activeIndex].type === AdType.CAROUSEL ? (
                            <div key={index}>
                              <DraggableItem
                                key={`${index}-before`}
                                index={index}
                                mediaName={media.name}
                                mediaType={media.type}
                                mediaSize={media.size}
                                mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                onDrop={handleMediasShuffle}
                                onNameChange={(value) => handleMediaNameChange(value, index)}
                                onDelete={handleMediaDelete}
                                type={DDType.DROP_TYPE}
                              />
                              <DraggableItem
                                key={index}
                                index={index}
                                mediaName={media.name}
                                mediaType={media.type}
                                mediaSize={media.size}
                                mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                onDrop={handleMediasShuffle}
                                onNameChange={(value) => handleMediaNameChange(value, index)}
                                onDelete={handleMediaDelete}
                                type={DDType.DRAG_TYPE}
                              />
                              {index === ads[activeIndex].medias.length - 1 && (
                                <DraggableItem
                                  key={`${index}-after`}
                                  index={index + 1}
                                  mediaName={media.name}
                                  mediaType={media.type}
                                  mediaSize={media.size}
                                  mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                  onDrop={handleMediasShuffle}
                                  onNameChange={(value) => handleMediaNameChange(value, index)}
                                  onDelete={handleMediaDelete}
                                  type={DDType.DROP_TYPE}
                                />
                              )}
                            </div>
                          ) : (
                            <div key={index}>
                              <ContentItem
                                index={index}
                                mediaName={media.name}
                                mediaType={media.type}
                                mediaSize={media.size}
                                mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                withDrag={false}
                                onNameChange={(value) => handleMediaNameChange(value, index)}
                                onDelete={handleMediaDelete}
                              />
                              <div className={styles.separator} />
                            </div>
                          );
                        })}
                      </div>
                    </DndProvider>
                  </div>
                )}

                {!!ads[activeIndex].medias.length &&
                  [AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(ads[activeIndex].type) && (
                    <div className={styles.multiSizeMediasWrapper}>
                      <div className={styles.medias}>
                        {ads[activeIndex].medias.map((media, index) => {
                          let ratio1 = null;
                          let ratio2 = null;
                          let transformationMultiple = null;
                          if (media.width && media.height) {
                            const gcd = greatestCommonDivisor(media.width, media.height);
                            ratio1 = media.width / gcd;
                            ratio2 = media.height / gcd;
                            if (ratio1 > 100) {
                              transformationMultiple = 1;
                            } else {
                              transformationMultiple = ratio1 === 1 ? 100 : ratio1 < 4 ? 60 : ratio1 < 6 ? 30 : 5;
                            }
                          }
                          const ratio = `${ratio1}:${ratio2}`;
                          console.log({ width: media.width, height: media.height, transformationMultiple, ratio });

                          return ratio1 && ratio2 && transformationMultiple ? (
                            <div
                              key={index}
                              className={clsx(styles.media, {
                                [styles.mediaSameRation]: duplicatedRatio && ratio === duplicatedRatio,
                              })}
                            >
                              <div className={styles.mediaHeader}>
                                {/*<Dropdown*/}
                                {/*  menu={{*/}
                                {/*    items: ['1:1', '5:4', '4:5', '4:3', '3:2', '7:5', '9:16', '16:9'].map((i) => ({*/}
                                {/*      key: i,*/}
                                {/*      label: i,*/}
                                {/*    })),*/}
                                {/*    onClick: ({ key }) => {*/}
                                {/*      console.log(key);*/}
                                {/*    },*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  <div className={styles.sizeSelector}>*/}
                                {/*    <span>{ratio}</span>*/}
                                {/*    <span>{<ChevronIcon />}</span>*/}
                                {/*  </div>*/}
                                {/*</Dropdown>*/}
                                <div className={styles.sizeSelector}>
                                  <span>
                                    {ratio1}:{ratio2}
                                  </span>
                                </div>
                                <DeleteIcon className={styles.delete} onClick={() => handleMediaDelete(index)} />
                              </div>
                              {media.type === 'image' ? (
                                <IKImage
                                  path={getMediaPath(media.imageKitName, media.isNew || false)}
                                  onError={(e) => logger.error(e)}
                                  transformation={[
                                    {
                                      width: `${ratio1 * transformationMultiple}`,
                                      height: `${ratio2 * transformationMultiple}`,
                                    },
                                  ]}
                                />
                              ) : (
                                <div
                                  className={styles.video}
                                  style={{
                                    width: `${ratio1 * transformationMultiple}px`,
                                    height: `${ratio2 * transformationMultiple}px`,
                                  }}
                                >
                                  <IKVideo
                                    path={getMediaPath(media.imageKitName, media.isNew || false)}
                                    onError={(e) => logger.error(e)}
                                    // transformation={[{ height: '112', width: '112' }]}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <></>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button className={styles.button} type="button" buttonType="secondary" disabled={loading} onClick={onHide}>
              {t('COMMON.CANCEL')}
            </Button>
            <Button
              className={styles.button}
              buttonType="primary"
              type="submit"
              disabled={
                !!duplicatedRatio ||
                !ads[activeIndex].title.length ||
                !!ads.find(({ medias }) => !medias.length) ||
                loading
              }
              onClick={handleSubmit}
            >
              {ad ? t('COMMON.SAVE') : t('COMMON.UPLOAD')}
            </Button>
          </div>
        </div>
      </Modal>
    </IKContext>
  );
}
