/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AdDnaSearchCriteria, OrderBy, ProjectSearchCriteria, SearchPaging, Sorting } from 'types/inputTypes';
import { Client, ClientPaymentModel, Clients, SocialMediaChannel, User } from 'types/types';
import { getAllUsers, getClients } from 'graphql/queries';
import { DATA } from 'utils/routingUtils';
import { getObjectUrlParams } from 'utils/stringUtils';
import { ModalContext, ModalContextProps } from 'contextProviders/ModalProvider';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { Ad, AdDna, AdDnaCtaPlacement, AdDnaLayout, AdsDna, AdType, Project, Projects } from 'types/businessTypes.d';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAd, getAdContentAsBlob, getAdsDna, getProject, getProjects } from 'graphql/businessQueries';
import { createAndDownloadBlobFile, makeCsv } from 'utils/fileUtils';
import { AD_DNA_COLUMNS, toCsv } from 'components/features/private/adsDna/adsDnaUtils';
import { buildAdViewUrl } from 'components/features/private/dashboard/DashboardProvider';
import CustomizeColumnsModal from 'components/features/private/adsDna/customizeColumnsModal';
import logger from 'utils/logger/logger';
import { askChatGpt, enhanceAdDnaViaAi, updateAdDna } from 'graphql/businessMutations';
import { toast } from 'react-toastify';
import ToasterInfo from 'components/common/toasterInfo';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { userHasClientUserRole, userHasPgUserRole } from 'utils/userUtils';
import { useTrackedSelector } from 'components/app/store';
import { downloadAd } from 'utils/businessUtils';
import MultiEditModal from 'components/features/private/adsDna/multiEditModal';
import { getContactUsMessage } from 'utils/i18nUtils';
import cloneDeep from 'lodash/cloneDeep';

export const AdsDnaContext = createContext({});

export const filtersInitialState = {
  [SearchParams.CLIENT_ID]: '',
  [SearchParams.PROJECT_ID]: '',
  [SearchParams.DNA_ID]: '',
};

export const buildAdsDnaUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.PROJECT_ID]?: string | null;
  [SearchParams.DNA_ID]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.PROJECT_ID]: input[SearchParams.PROJECT_ID] || filtersInitialState[SearchParams.PROJECT_ID],
    [SearchParams.DNA_ID]: input[SearchParams.DNA_ID] || filtersInitialState[SearchParams.DNA_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${DATA}?${urlParams}`;
};

export interface AdsDnaContextProps {
  loading: boolean;
  error: boolean;
  items: AdDna[];

  onCustomizeColumns: () => void;
  selectedColumns: AD_DNA_COLUMNS[];

  users: User[];

  onClientSelected: (id: string) => void;
  selectedClientId: string | null;
  clients: Client[];

  onProjectSelected: (id: string) => void;
  selectedProjectId: string | null;
  projects: Project[];

  order: OrderBy;
  setOrder: (value: OrderBy) => void;

  onDownload: (id: string) => void;
  onExportAsCsv: () => void;
  onDownloadMultiple: () => void;
  onEditMultiple: () => void;

  selectedAdDna: AdDna | null;
  onSetSelectedAdDna: (id: string) => void;
  onShowAdDna: (adDna: AdDna) => void;
  onNavigateToAd: (adDna: AdDna) => void;
  onDeleteAdDna: (id: string) => void;
  onDeleteSelected: () => void;
  onUpdateAdDna: (adDna: AdDna) => void;
  onEnhanceAdDnaViaAi: (adDna: AdDna) => void;
  adDnaAd: Ad | null;
  adDnaProject: Project | null;

  pagination: AdsDnaPaginationProps;
  selection: AdsDnaSelectionProps;
  filter: AdsDnaFilterProps;
  chat: AdsDnaChatProps;
}

export const paginationInitialState = {
  page: 0,
  perPage: 100,
  pageCount: 0,
  totalSize: 0,
};

export const selectionInitialState = {
  selectedIndexes: [],
};

export interface AdsDnaPaginationProps {
  page: number;
  pageCount: number;
  perPage: number;
  totalSize: number;
  setPage: (value: number) => void;
  setPageCount: (value: number) => void;
  setPerPage: (value: number) => void;
  setTotalSize: (value: number) => void;
}

export interface AdsDnaSelectionProps {
  selectedIndexes: number[];
  onSelected: (value: number[]) => void;
}

export interface AdsDnaFilterProps {
  pgId: string | null;
  setPgId: (value: string | null) => void;
  pgIdAuthor: string | null;
  setPgIdAuthor: (value: string | null) => void;

  title: string | null;
  setTitle: (value: string | null) => void;
  paymentModel: ClientPaymentModel | null;
  setPaymentModel: (value: ClientPaymentModel | null) => void;
  channel: SocialMediaChannel | null;
  setChannel: (value: SocialMediaChannel | null) => void;

  layout: AdDnaLayout | null;
  setLayout: (value: AdDnaLayout | null) => void;
  assetRatio: string | null;
  setAssetRatio: (value: string | null) => void;
  assetFormat: AdType | null;
  setAssetFormat: (value: AdType | null) => void;
  slidesNumber: number | null;
  setSlidesNumber: (value: number | null) => void;
  videoLength: number | null;
  setVideoLength: (value: number | null) => void;

  copyPlain: string | null;
  setCopyPlain: (value: string | null) => void;
  scriptPlain: string | null;
  setScriptPlain: (value: string | null) => void;

  ctaPlacement: AdDnaCtaPlacement | null;
  setCtaPlacement: (value: AdDnaCtaPlacement | null) => void;

  adScore: number | null;
  setAdScore: (value: number | null) => void;
  adScoreMeta: number | null;
  setAdScoreMeta: (value: number | null) => void;
  adScoreTiktok: number | null;
  setAdScoreTiktok: (value: number | null) => void;
}

export interface AdsDnaChatProps {
  initChatGptLoading: boolean;
  initChatGptError: boolean;
  onAskChatGpt: (content: string) => Promise<string | null>;
  askChatGptLoading: boolean;
  askChatGptError: boolean;
}

const AdsDnaProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    searchParams: {
      [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID],
      [SearchParams.PROJECT_ID]: _projectId = filtersInitialState[SearchParams.PROJECT_ID],
      [SearchParams.DNA_ID]: _dnaId = filtersInitialState[SearchParams.DNA_ID],
    },
  } = useCustomLocation();

  const {
    auth: { user },
  } = useTrackedSelector();

  const [doFetchClients, { data: clientsData, loading: clientsLoading, error: clientsError }] = useLazyQuery<{
    getClients: Clients;
  }>(getClients);
  const [doFetchUsers, { data: usersData, loading: userLoading, error: usersError }] = useLazyQuery<{
    getAllUsers?: User[];
  }>(getAllUsers);
  const [doFetchProjects, { data: projectsData, loading: projectsLoading, error: projectsError }] = useLazyQuery<{
    getProjects: Projects;
  }>(getProjects);
  const [doFetchAdsDna, { data: adsDnaData, loading: adsDnaLoading, error: adsDnaError }] = useLazyQuery<{
    getAdsDna: AdsDna;
  }>(getAdsDna);
  // const [doInitChatGptSession, { loading: initChatGptLoading, error: initChatGptError }] = useMutation<{
  //   initChatGptSession?: string;
  // }>(initChatGptSession);
  const [doAskChatGpt, { loading: askChatGptLoading, error: askChatGptError }] = useMutation<{
    askChatGpt?: string;
  }>(askChatGpt);

  const [doFetchProject] = useLazyQuery<{ getProject?: Project }>(getProject);
  const [doFetchAd] = useLazyQuery<{ getAd?: Ad }>(getAd);
  const [doUpdateAdDna] = useMutation<{ updateAdDna?: AdDna }>(updateAdDna);
  const [doEnhanceAdDnaViaAi, { loading: enhanceViaAiLoading }] = useMutation<{
    enhanceAdDnaViaAi?: AdDna;
  }>(enhanceAdDnaViaAi);
  const [doDownloadAd] = useLazyQuery<{ getAdContentAsBlob?: string }>(getAdContentAsBlob);

  const { openModal, toggleModal } = useContext(ModalContext) as ModalContextProps;

  const [dataFetchingCompleted, setDataFetchingCompleted] = useState(false);
  const [items, setItems] = useState<AdDna[]>([]);
  const [order, setOrder] = useState(OrderBy.InsightsMetaSpendDesc);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(selectionInitialState.selectedIndexes);

  const [selectedColumns, setSelectedColumns] = useState(Object.values(AD_DNA_COLUMNS));

  const [pgId, setPgId] = useState<string | null>(null);
  const [pgIdAuthor, setPgIdAuthor] = useState<string | null>(null);

  const [title, setTitle] = useState<string | null>(null);
  const [paymentModel, setPaymentModel] = useState<ClientPaymentModel | null>(null);
  const [channel, setChannel] = useState<SocialMediaChannel | null>(null);

  const [layout, setLayout] = useState<AdDnaLayout | null>(null);
  const [assetRatio, setAssetRatio] = useState<string | null>(null);
  const [assetFormat, setAssetFormat] = useState<AdType | null>(null);
  const [slidesNumber, setSlidesNumber] = useState<number | null>(null);
  const [videoLength, setVideoLength] = useState<number | null>(null);

  const [copyPlain, setCopyPlain] = useState<string | null>(null);
  const [scriptPlain, setScriptPlain] = useState<string | null>(null);

  const [ctaPlacement, setCtaPlacement] = useState<AdDnaCtaPlacement | null>(null);

  const [adScore, setAdScore] = useState<number | null>(null);
  const [adScoreMeta, setAdScoreMeta] = useState<number | null>(null);
  const [adScoreTiktok, setAdScoreTiktok] = useState<number | null>(null);

  const [selectedClientId, setSelectedClientId] = useState<string>(_clientId || '');
  const [selectedProjectId, setSelectedProjectId] = useState<string>(_projectId || '');
  const [selectedAdDnaId, setSelectedAdDnaId] = useState<string>(_dnaId || '');

  const [adDnaAd, setAdDnaAd] = useState<Ad | null>(null);
  const [adDnaProject, setAdDnaProject] = useState<Project | null>(null);

  const [page, setPage] = useState(paginationInitialState.page);
  const [perPage, setPerPage] = useState(paginationInitialState.perPage);
  const [totalSize, setTotalSize] = useState(paginationInitialState.totalSize);
  const [pageCount, setPageCount] = useState(paginationInitialState.pageCount);

  // const [chatGptSession, setChatGptSession] = useState<string | null>(null);

  const clients = useMemo(() => clientsData?.getClients?.items || [], [clientsData?.getClients?.items]);
  const users = useMemo(() => usersData?.getAllUsers || [], [usersData?.getAllUsers]);
  const usersById = useMemo(() => new Map(users.map((c) => [c.id, c])), [users]);
  const projects = useMemo(() => projectsData?.getProjects?.items || [], [projectsData?.getProjects?.items]);
  const projectsById = useMemo(() => new Map(projects.map((c) => [c.id, c])), [projects]);

  // const selectedItems = useMemo(
  //   () => (!!items?.length && !!selectedIndexes.length ? selectedIndexes.map((index) => items[index]) : []),
  //   [items, selectedIndexes],
  // );
  const itemsById = useMemo(() => new Map(items.map((i) => [i.id, i])), [items]);
  const selectedAdDna = useMemo(() => itemsById.get(selectedAdDnaId) || null, [itemsById, selectedAdDnaId]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: selectedClientId || _clientId || filtersInitialState[SearchParams.CLIENT_ID],
        [SearchParams.PROJECT_ID]: selectedProjectId || _projectId || filtersInitialState[SearchParams.PROJECT_ID],
        [SearchParams.DNA_ID]: selectedAdDnaId || _dnaId || filtersInitialState[SearchParams.DNA_ID],
      }),
    [_clientId, _dnaId, _projectId, selectedAdDnaId, selectedClientId, selectedProjectId],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);
  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const fetchAdsDna = useCallback(() => {
    const searchCriteria = {
      clientIds: !!selectedClientId?.length ? [selectedClientId] : null,
      projectId: selectedProjectId,
      pgId,
      pgIdAuthor,
      title,
      paymentModel,
      channel,
      layout,
      assetRatio,
      assetFormat,
      slidesNumber,
      videoLength,
      copyPlain,
      scriptPlain,
      ctaPlacement,
      adScore,
      adScoreMeta,
      adScoreTiktok,
    } as AdDnaSearchCriteria;

    doFetchAdsDna({
      variables: {
        paging: {
          page,
          perPage,
        } as SearchPaging,
        sorting: { orderBy: order } as Sorting,
        searchCriteria,
      },
    });

    // something has changed in the shown DNA table, let's re-init the GPT session
    // setChatGptSession(null);
    // doInitChatGptSession({ variables: { searchCriteria } })
    //   .then((result) => {
    //     if (!!result?.data?.initChatGptSession?.length) {
    //       setChatGptSession(result.data.initChatGptSession);
    //     } else {
    //       logger.error(`Error initialising chat GPT session`);
    //     }
    //   })
    //   .catch((e) => {
    //     logger.error(`Error initialising chat GPT session: ${e}`);
    //   });
  }, [
    adScore,
    adScoreMeta,
    adScoreTiktok,
    assetFormat,
    assetRatio,
    channel,
    copyPlain,
    ctaPlacement,
    doFetchAdsDna,
    layout,
    order,
    page,
    paymentModel,
    perPage,
    pgId,
    pgIdAuthor,
    scriptPlain,
    selectedClientId,
    selectedProjectId,
    slidesNumber,
    title,
    videoLength,
  ]);

  const onHide = useCallback(() => toggleModal(false), [toggleModal]);

  const onClientSelected = useCallback(
    (id: string) => {
      setSelectedClientId(id);
      setSelectedProjectId('');
      navigate(
        buildAdsDnaUrl({
          [SearchParams.CLIENT_ID]: id,
          [SearchParams.PROJECT_ID]: '',
        }),
      );
    },
    [navigate],
  );

  const onProjectSelected = useCallback(
    (id: string) => {
      setSelectedProjectId(id);
      navigate(
        buildAdsDnaUrl({
          [SearchParams.CLIENT_ID]: selectedClientId,
          [SearchParams.PROJECT_ID]: id,
        }),
      );
    },
    [navigate, selectedClientId],
  );

  const onCustomizeColumns = useCallback(() => {
    openModal({
      modalContent: (
        <CustomizeColumnsModal
          columns={selectedColumns}
          onHide={onHide}
          onDone={(value) => {
            setSelectedColumns(value);
            onHide();
          }}
        />
      ),
      onHide,
    });
  }, [onHide, openModal, selectedColumns]);

  const onShowAdDna = useCallback((adDna: AdDna) => {
    setSelectedAdDnaId(adDna.id);
  }, []);

  const onSetSelectedAdDna = useCallback(
    (id: string) => {
      if (!id.length) {
        // back from the ad dna details
        setAdDnaAd(null);
        setAdDnaProject(null);
        fetchAdsDna();
      }
      setSelectedAdDnaId(id);
      navigate(
        buildAdsDnaUrl({
          [SearchParams.CLIENT_ID]: selectedClientId,
          [SearchParams.PROJECT_ID]: selectedProjectId,
          [SearchParams.DNA_ID]: id,
        }),
      );
    },
    [fetchAdsDna, navigate, selectedClientId, selectedProjectId],
  );

  const onNavigateToAd = useCallback(
    (adDna: AdDna) => {
      navigate(
        buildAdViewUrl(adDna.adId, {
          [SearchParams.CLIENT_ID]: adDna.clientId,
          [SearchParams.PROJECT_ID]: adDna.projectId,
        }),
      );
    },
    [navigate],
  );

  const onAdDownload = useCallback(
    (ad: Ad) => {
      doDownloadAd({ variables: { id: ad.id } })
        .then((result) => {
          if (!!result?.data?.getAdContentAsBlob?.length) {
            downloadAd(ad, result.data.getAdContentAsBlob);
          } else {
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error downloading ad: ${e}`);
          showErrorNotification();
        });
    },
    [doDownloadAd, showErrorNotification],
  );

  const onDownload = useCallback(
    (id: string) => {
      const adDna = itemsById.get(id);
      if (adDna) {
        doFetchAd({ variables: { id: adDna.adId } })
          .then((result) => {
            if (result?.data?.getAd) {
              onAdDownload(result?.data?.getAd);
            } else {
              logger.error(`Error fetching ad: ${adDna.adId}`);
              showErrorNotification();
            }
          })
          .catch((e) => {
            logger.error(`Error fetching ad: ${adDna.adId}: ${e}`);
            showErrorNotification();
          });
      }
    },
    [doFetchAd, itemsById, onAdDownload, showErrorNotification],
  );

  const onExportAsCsv = useCallback(() => {
    const finalItems = !!selectedIndexes.length
      ? items.filter((item, index) => selectedIndexes.includes(index))
      : items;
    const { headers, data } = toCsv(finalItems, t, usersById, projectsById, selectedColumns);
    const csvData = makeCsv(headers, data);
    const blob = new Blob([csvData]);
    createAndDownloadBlobFile(false, URL.createObjectURL(blob), 'Ads_DNA.csv');
  }, [items, projectsById, selectedColumns, selectedIndexes, t, usersById]);

  const onDownloadMultiple = useCallback(() => {
    // todo
  }, []);

  const onEditMultiple = useCallback(() => {
    openModal({
      modalContent: (
        <MultiEditModal
          ids={selectedIndexes.map((i) => items[i].id)}
          clients={clients}
          selectedClientId={selectedClientId}
          users={users}
          onHide={onHide}
          onDone={() => {
            fetchAdsDna();
            onHide();
          }}
        />
      ),
      onHide,
    });
  }, [clients, fetchAdsDna, items, onHide, openModal, selectedClientId, selectedIndexes, users]);

  const onDeleteAdDna = useCallback(() => {
    // todo
  }, []);

  const onDeleteSelected = useCallback(() => {
    // todo
  }, []);

  const onUpdateAdDna = useCallback(
    (adDna: AdDna) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete adDna['__typename'];

      adDna.roles?.forEach((i) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete i['__typename'];
      });
      adDna.personas?.forEach((i) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete i['__typename'];
      });

      doUpdateAdDna({ variables: { input: adDna } })
        .then((result) => {
          const updated = result?.data?.updateAdDna;
          if (updated) {
            showSuccessNotification(t('PRIVATE.USER.ADS_DNA.AD_DNA_UPDATE_SUCCESS'));
            setItems((prev) => {
              const index = prev.findIndex(({ id }) => id === adDna.id);
              const newValue = cloneDeep(prev);
              if (index !== -1) {
                newValue[index] = updated;
              }
              return newValue;
            });
          } else {
            logger.error(`Error updating adDna ${adDna.id}`);
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error updating adDna ${adDna.id}: ${e}`);
          showErrorNotification();
        });
    },
    [doUpdateAdDna, showErrorNotification, showSuccessNotification, t],
  );

  const onEnhanceAdDnaViaAi = useCallback(
    (adDna: AdDna) => {
      doEnhanceAdDnaViaAi({ variables: { id: adDna.id } })
        .then((result) => {
          const updated = result?.data?.enhanceAdDnaViaAi;
          if (updated) {
            showSuccessNotification(t('PRIVATE.USER.ADS_DNA.ENHANCE_VIA_AI_SUCCESS'));
            setItems((prev) => {
              const index = prev.findIndex(({ id }) => id === adDna.id);
              const newValue = cloneDeep(prev);
              if (index !== -1) {
                newValue[index] = updated;
              }
              return newValue;
            });
          } else {
            logger.error(`Error enhancing adDna ${adDna.id}`);
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error enhancing adDna ${adDna.id}: ${e}`);
          showErrorNotification();
        });
    },
    [doEnhanceAdDnaViaAi, showErrorNotification, showSuccessNotification, t],
  );

  const onSelected = useCallback((indexes: number[]) => {
    setSelectedIndexes(indexes);
  }, []);

  const onAskChatGpt = useCallback(
    async (content: string) => {
      // if (!chatGptSession?.length) {
      //   showInfoNotification(t('PRIVATE.USER.ADS_DNA.CHAT.SESSION_ERROR'));
      //   return Promise.resolve(null);
      // }
      if (!items.length) {
        return Promise.resolve(null);
      }

      items.forEach((i) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete i['__typename'];

        i.roles?.forEach((role) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete role['__typename'];
        });
        i.personas?.forEach((j) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete j['__typename'];
        });
      });

      const result =
        (await doAskChatGpt({ variables: { adDnas: items, question: content } }))?.data?.askChatGpt || null;
      return Promise.resolve(result);
    },
    [doAskChatGpt, items],
  );

  // fetch ad and project related to particular ad dna
  useEffect(() => {
    if (selectedAdDna) {
      doFetchAd({ variables: { id: selectedAdDna.adId } })
        .then((result) => {
          if (result?.data?.getAd) setAdDnaAd(result?.data.getAd);
          else {
            logger.error(`Error fetching ad: ${selectedAdDna.adId}`);
          }
        })
        .catch((e) => logger.error(`Error fetching ad: ${selectedAdDna.adId}: ${e}`));

      doFetchProject({ variables: { id: selectedAdDna.projectId } })
        .then((result) => {
          if (result?.data?.getProject) setAdDnaProject(result?.data.getProject);
          else {
            logger.error(`Error fetching project: ${selectedAdDna.adId}`);
          }
        })
        .catch((e) => logger.error(`Error fetching project: ${selectedAdDna.adId}: ${e}`));
    }
  }, [doFetchAd, doFetchProject, selectedAdDna]);

  // define displayed items
  useEffect(() => {
    let items: AdDna[] = [];
    let totalSize = 0;
    if (!adsDnaLoading && !adsDnaError) {
      items = adsDnaData?.getAdsDna?.items || [];
      totalSize = adsDnaData?.getAdsDna?.paging?.totalCount || 0;
    }
    setDataFetchingCompleted(!!adsDnaData?.getAdsDna);
    setPageCount(Math.ceil(totalSize / perPage));
    setTotalSize(totalSize);
    setItems(items);
  }, [perPage, adsDnaLoading, adsDnaError, adsDnaData?.getAdsDna]);

  // set client id for client user
  useEffect(() => {
    if (user && userHasClientUserRole(user) && !selectedClientId?.length) {
      setSelectedClientId(user.clientId || '');
    }
  }, [selectedClientId?.length, user]);

  // fetch of all entities
  useEffect(() => {
    if (!user) return;
    if (userHasClientUserRole(user) && !selectedClientId?.length) return;

    if (userHasPgUserRole(user)) {
      doFetchClients({
        variables: {
          sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
          paging: { page: 0, perPage: 100 } as SearchPaging,
        },
      });
    }

    doFetchUsers();

    doFetchProjects({
      variables: {
        paging: { page: 0, perPage: 300 } as SearchPaging,
        sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
        searchCriteria: { clientId: user.clientId } as ProjectSearchCriteria,
      },
    });

    fetchAdsDna();
  }, [doFetchClients, doFetchProjects, doFetchUsers, fetchAdsDna, selectedClientId, user]);

  useEffect(() => {
    navigate(`/${DATA}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading:
      clientsLoading ||
      adsDnaLoading ||
      userLoading ||
      projectsLoading ||
      enhanceViaAiLoading ||
      !dataFetchingCompleted,
    error: !!clientsError || !!adsDnaError || !!usersError || !!projectsError,
    items,

    onCustomizeColumns,
    selectedColumns,

    users,

    selectedClientId,
    clients,
    onClientSelected,

    selectedProjectId,
    projects,
    onProjectSelected,

    order,
    setOrder,

    onDownload,
    onExportAsCsv,
    onDownloadMultiple,
    onEditMultiple,

    selectedAdDna,
    onSetSelectedAdDna,
    onNavigateToAd,
    onShowAdDna,
    onDeleteAdDna,
    onDeleteSelected,
    onUpdateAdDna,
    onEnhanceAdDnaViaAi,
    adDnaAd,
    adDnaProject,

    pagination: {
      page,
      pageCount,
      perPage,
      totalSize,
      setPage,
      setPageCount,
      setPerPage,
      setTotalSize,
    },
    selection: {
      selectedIndexes,
      onSelected,
    },
    filter: {
      pgId,
      setPgId,
      pgIdAuthor,
      setPgIdAuthor,

      title,
      setTitle,
      paymentModel,
      setPaymentModel,
      channel,
      setChannel,

      layout,
      setLayout,
      assetRatio,
      setAssetRatio,
      assetFormat,
      setAssetFormat,
      slidesNumber,
      setSlidesNumber,
      videoLength,
      setVideoLength,

      copyPlain,
      setCopyPlain,
      scriptPlain,
      setScriptPlain,

      ctaPlacement,
      setCtaPlacement,

      adScore,
      setAdScore,
      adScoreMeta,
      setAdScoreMeta,
      adScoreTiktok,
      setAdScoreTiktok,
    },
    chat: {
      // initChatGptLoading,
      // initChatGptError: !!initChatGptError,
      initChatGptLoading: false,
      initChatGptError: false,
      onAskChatGpt,
      askChatGptLoading,
      askChatGptError: !!askChatGptError,
    },
  } as AdsDnaContextProps;

  // console.log(value);
  return <AdsDnaContext.Provider value={value}>{children}</AdsDnaContext.Provider>;
};

export default AdsDnaProvider;
