import React from 'react';
import clsx from 'classnames';
import { Props } from './type';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  disabled,
  label,
  alignLabel = 'left',
  labelAfter = false,
  style,
  className,
  labelClassName,
  darkMode = false,
  ...rest
}: Props): JSX.Element => {
  const checkboxContainerStyle = clsx(styles.checkboxContainer, {
    [`${className}`]: !!className,
    [styles.checkboxContainerRight]: alignLabel === 'right',
    [styles.labelAfter]: labelAfter,
    [styles.disabled]: disabled,
    [styles.checkboxContainerDarkMode]: darkMode,
  });

  const labelComponent = (
    <p
      className={clsx({
        [`${labelClassName}`]: !!labelClassName,
      })}
    >
      {label}
    </p>
  );

  return (
    <label style={style} className={checkboxContainerStyle}>
      {!!label?.length && !labelAfter && labelComponent}
      <div className={clsx(styles.container, { [styles.containerDarkMode]: darkMode })}>
        <input type="checkbox" {...rest} disabled={disabled} />
        <span
          className={clsx(styles.checkmark, {
            [`${labelClassName}`]: !!labelClassName,
            [styles.checkmarkDarkMode]: darkMode,
          })}
        />
      </div>
      {!!label?.length && labelAfter && labelComponent}
    </label>
  );
};

export default Checkbox;
