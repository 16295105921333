import { Ad, AdType } from 'types/businessTypes.d';
import { TFunction } from 'react-i18next';
import { SocialMediaChannel } from 'types/types.d';
import { base64ToUrl, base64ZipPrefix, createAndDownloadBlobFile, getBase64Prefix } from 'utils/fileUtils';
import { ContentType } from 'types/contentTypes';

export const projectTitleMaxLength = 100;
export const adTitleMaxLength = 400;

export const getImageKitPath = ({
  clientId,
  projectId,
  adId,
  versionId,
  fileName,
}: {
  clientId: string;
  projectId: string;
  adId: string;
  versionId: string;
  fileName: string;
}): string => `/client_${clientId}/project_${projectId}/ad_${adId}/version_${versionId}/${fileName}`;

export const getAdTypeByImageKitType = (type: string): AdType => {
  switch (type) {
    case 'image':
      return AdType.SINGLE_IMAGE;
    default:
      return AdType.SINGLE_VIDEO;
  }
};

export const getLocalisedAdType = (type: AdType, t: TFunction): string => {
  return t(`AD_TYPE.${type}`);
};

export const getAssetsVariationsMotionTypes = (channel: SocialMediaChannel): string[] => {
  switch (channel) {
    case SocialMediaChannel.FACEBOOK:
      return ['1:1', '9:16', '16:9'];
    case SocialMediaChannel.PINTEREST:
      return ['1:1', '9:16', '4:5'];
    case SocialMediaChannel.SNAPCHAT:
      return ['16:9'];
    case SocialMediaChannel.TIK_TOK:
      return ['9:16'];
    case SocialMediaChannel.GOOGLE:
      return [
        '1024x768',
        '1024x90',
        '980x120',
        '980x90',
        '970x90',
        '970x66',
        '970x250',
        '960x90',
        '950x90',
        '930x180',
        '768x1024',
        '750x300',
        '750x200',
        '750x100',
        '736x414',
        '728x90',
        '600x314',
        '580x400',
        '480x320',
        '480x32',
        '468x60',
        '425x600',
        '414x736',
        '375x50',
        '336x280',
        '320x480',
        '320x400',
        '320x100',
        '320x50',
        '300x1050',
        '300x600',
        '300x250',
        '300x100',
        '300x57',
        '300x50',
        '300x31',
        '292x30',
        '250x360',
        '250x250',
        '240x400',
        '240x133',
        '200x446',
        '200x200',
        '160x600',
        '120x600',
        '120x60',
      ];
  }
};

export const getAssetsVariationsStaticTypes = (channel: SocialMediaChannel): string[] => {
  switch (channel) {
    case SocialMediaChannel.FACEBOOK:
      return ['1200x628px', '640x640px', '720x1280px'];
    case SocialMediaChannel.PINTEREST:
      return ['1:1', '2:3', '9:16', '4:5', '16:9 (1:1,91)'];
    case SocialMediaChannel.SNAPCHAT:
      return ['9:16', '360x600px'];
    case SocialMediaChannel.TIK_TOK:
      return ['9:16'];
    case SocialMediaChannel.GOOGLE:
      return [
        '1024x768',
        '1024x90',
        '980x120',
        '980x90',
        '970x90',
        '970x66',
        '970x250',
        '960x90',
        '950x90',
        '930x180',
        '768x1024',
        '750x300',
        '750x200',
        '750x100',
        '736x414',
        '728x90',
        '600x314',
        '580x400',
        '480x320',
        '480x32',
        '468x60',
        '425x600',
        '414x736',
        '375x50',
        '336x280',
        '320x480',
        '320x400',
        '320x100',
        '320x50',
        '300x1050',
        '300x600',
        '300x250',
        '300x100',
        '300x57',
        '300x50',
        '300x31',
        '292x30',
        '250x360',
        '250x250',
        '240x400',
        '240x133',
        '200x446',
        '200x200',
        '160x600',
        '120x600',
        '120x60',
      ];
  }
};

/**
 * If one media is present then download such file otherwise expect a zip archive
 */
export const downloadAd = (ad: Ad, base64Content: string) => {
  const fileName = ad.pgId?.length ? `${ad.pgId} - ${ad.title}` : ad.title;
  const medias = ad.versions[0].medias;
  const extension = medias.length === 1 ? medias[0].mime.substring(medias[0].mime.indexOf('/') + 1) : 'zip';
  const contentType = medias.length === 1 ? medias[0].mime : ContentType.APPLICATION_ZIP;
  const base64Prefix = medias.length === 1 ? getBase64Prefix(medias[0].mime) : base64ZipPrefix;
  const body = contentType === ContentType.APPLICATION_ZIP ? base64ToUrl(base64Content, contentType) : base64Content;
  createAndDownloadBlobFile(
    contentType !== ContentType.APPLICATION_ZIP,
    body,
    `${fileName}.${extension}`,
    base64Prefix,
  );
};

export const getWhitelabelUrl = (prefix: string): string => {
  const { REACT_APP_WHITELABEL_SUFFIX: suffix } = process.env;
  return `https://${prefix}${suffix}`;
};

export const greatestCommonDivisor = (a: number, b: number): number => {
  if (!b) {
    return a;
  }
  return greatestCommonDivisor(b, a % b);
};

export const getAssetRatio = (width: number | null | undefined, height: number | null | undefined): string => {
  if (!width || !height) return '';

  const gcd = greatestCommonDivisor(width, height);
  return `${width / gcd}:${height / gcd}`;
};
