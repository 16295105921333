import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import { ReactComponent as ChevronDownIcon } from 'assets/img/chevronDown.svg';
import OutsideClick from 'components/common/outSideClick';
import DropDownMenu from 'components/common/dropdownMenu';
import { Variant } from 'components/common/dropdownMenu/types';
import { Props } from 'components/common/dropdownInput/type';
import { useTranslation } from 'react-i18next';
import styles from './DropdownInput.module.scss';

const DropDownInput = ({
  variants,
  disabled,
  takeValue,
  initialValue,
  className,
  dropDownWrapperClassname,
  menuContainerClassname,
  icon = <ChevronDownIcon />,
  darkMode = false,
  ...rest
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [openDropdown, setIsOpened] = useState<boolean>(false);
  const [chosenValue, setChosenValue] = useState<string>('');

  const openDropDown = () => {
    if (!disabled) {
      setIsOpened(!openDropdown);
    }
  };

  const chooseValue = (arg: Variant) => {
    setIsOpened(!openDropdown);
    setChosenValue(arg.id);
    takeValue(arg.name, arg.id);
  };

  const containerStyle = clsx(styles.container, {
    [`${className}`]: !!className,
  });

  const dropDownStyle = clsx(styles.dropDownWrapper, dropDownWrapperClassname, {
    [styles.darkMode]: darkMode,
    [styles.disabled]: disabled,
  });

  useEffect(() => {
    setChosenValue(initialValue?.id || '');
  }, [initialValue]);

  return (
    <div className={containerStyle} {...rest}>
      <OutsideClick onOutsideClick={() => setIsOpened(false)}>
        <div className={styles.container} onClick={openDropDown}>
          <div className={dropDownStyle}>
            <p className={clsx({ [styles.placeholder]: !chosenValue.length })}>
              {chosenValue === ''
                ? t('COMMON.SELECT')
                : variants.find((i) => i.id === chosenValue)?.name || t('COMMON.SELECT')}
            </p>
            <span
              className={clsx(styles.icon, {
                [styles.iconDarkMode]: darkMode,
              })}
            >
              {icon}
            </span>
          </div>
        </div>
        {!disabled && variants.length > 0 && (
          <div className={clsx(styles.menu, { [styles.menuDarkMode]: darkMode })}>
            <DropDownMenu
              open={openDropdown}
              variants={variants}
              chosenValue={chosenValue}
              chooseValue={chooseValue}
              containerClassname={menuContainerClassname}
              darkMode={darkMode}
            />
          </div>
        )}
      </OutsideClick>
    </div>
  );
};

export default DropDownInput;
