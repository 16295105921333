import {
  ClientPaymentModel,
  ClientPersona,
  Gender,
  Location,
  Maybe,
  Paging,
  Scalars,
  SocialMediaChannel,
} from './types';

/**
 * Before a media belongs to a created ad it is stored in this folder and then moved to the actual destination
 */
export const IMAGE_KIT_TEMP_FOLDER = '/temp';

export const SUPPORTED_IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'tiff', 'svg'];
export const SUPPORTED_VIDEO_TYPES = []; // todo

export enum AdPlatform {
  META = 'META',
  TIKTOK = 'TIKTOK',
}

export type ProjectAndBrief = {
  __typename?: 'ProjectAndBrief';
  project: Project;
  brief?: Maybe<Brief>;
};

export type Projects = {
  __typename?: 'Projects';
  items: Array<Project>;
  paging: Paging;
};

export type Project = {
  __typename?: 'Project';
  clientId: Scalars['String'];

  id: Scalars['String'];
  paymentModel: ClientPaymentModel;
  title: Scalars['String'];
  deliveryDate: Scalars['String'];
  ugc?: Maybe<Scalars['Boolean']>;

  briefId?: Maybe<Scalars['String']>;

  creatorIds?: Maybe<Array<Scalars['String']>>;

  tikTokAdvertiserId?: Maybe<Array<Scalars['String']>>;

  createdDate: Scalars['String'];
};

export type Briefs = {
  __typename?: 'Briefs';
  items: Array<Brief>;
  paging: Paging;
};

export type Brief = {
  projectId: Scalars['String'];
  id: Scalars['String'];

  paymentModel: ClientPaymentModel;
  state: BriefState;

  main: BriefMain;
  concept?: Main<BriefConcept>;
  deliverables?: Maybe<BriefDeliverables>;
  ugc?: Maybe<BriefUgc>;
  creators?: Maybe<BriefCreators>;
  copy?: Maybe<BriefCopy>;
  creative?: Maybe<BriefCreative>;

  comments?: Maybe<Array<BriefComment>>;

  createdDate?: Maybe<Scalars['String']>;
};

export enum BriefState {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
}

export type BriefMain = {
  title: Scalars['String'];
  channel: SocialMediaChannel;
  ugc?: Maybe<Scalars['Boolean']>;
  deadlineForCreators?: Maybe<Scalars['String']>;
};

export type BriefConcept = {
  description?: Maybe<Scalars['String']>;
  adReferences?: Maybe<Scalars['String']>;
  productFeatures?: Maybe<Scalars['String']>;
  valuePropositions?: Maybe<Scalars['String']>;
  pastLearnings?: Maybe<Scalars['String']>;
  mandatoryElements?: Maybe<Scalars['String']>;
  materials?: Maybe<Array<BriefConceptMaterial>>;
};

export type BriefConceptMaterial = {
  fileName: Scalars['String'];
  content: Scalars['String'];
};

export type BriefDeliverables = {
  creatorsNumber?: Maybe<Scalars['Int']>;
  videosNumber?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Array<BriefDeliverablesLayout>>;
  videoLength?: Maybe<Array<BriefDeliverablesVideoLength>>;

  assetsVariationsNumber?: Maybe<Scalars['Int']>;
  assetsVariationsTypes?: Maybe<Array<Scalars['String']>>;
  assetsVariationsMotionNumber?: Maybe<Scalars['Int']>;
  assetsVariationsMotionTypes?: Maybe<Array<Scalars['String']>>;
  assetsVariationsStaticsNumber?: Maybe<Scalars['Int']>;
  assetsVariationsStaticsTypes?: Maybe<Array<Scalars['String']>>;
};

export const MOTION_TYPES = ['1:1', '2:3', '4:5', '9:16', '16:9', '16:9 (1:1,91)'];

export enum BriefDeliverablesLayout {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  SQUARE = 'SQUARE',
}

export enum BriefDeliverablesVideoLength {
  UP_TO_15_SECONDS = 'UP_TO_15_SECONDS',
  SECONDS_15_30 = 'SECONDS_15_30',
  SECONDS_30_60 = 'SECONDS_30_60',
}

export type BriefUgc = {
  script?: Maybe<Scalars['String']>;
  scriptTones?: Maybe<Array<BriefUgcScriptTone>>;
  scriptTonesOther?: Maybe<Scalars['String']>;
  requirementsForCreators?: Maybe<Scalars['String']>;
  materials?: Maybe<Array<BriefConceptMaterial>>;
};

export enum BriefUgcScriptTone {
  INFORMAL = 'INFORMAL',
  FORMAL = 'FORMAL',
  HUMOROUS = 'HUMOROUS',
  INFORMATIVE = 'INFORMATIVE',
  NOSTALGIC = 'NOSTALGIC',
  PLAYFUL = 'PLAYFUL',
  PROVOCATIVE = 'PROVOCATIVE',
  SMART = 'SMART',
  CREDIBLE = 'CREDIBLE',
}

export type BriefCreators = {
  creatorsNumber?: Maybe<Scalars['Int']>;
  locations?: Maybe<Array<Location>>;
  locationOther?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Scalars['String']>>;
  languageOther?: Maybe<Scalars['String']>;
  genders?: Maybe<Array<Gender>>;
  age?: Maybe<Array<BriefCreatorsAge>>;
  notes?: Maybe<Scalars['String']>;
  requirementsForCreators?: Maybe<Scalars['String']>;
};

export enum BriefCreatorsAge {
  _18_24 = '_18_24',
  _25_34 = '_25_34',
  _35_AND_MORE = '_35_AND_MORE',
  ANY = 'ANY',
}

export type BriefCopy = {
  copyText?: Maybe<Scalars['String']>;
  copyTones?: Maybe<Array<BriefCopyTone>>;
  copyToneOther?: Maybe<Scalars['String']>;
};

export enum BriefCopyTone {
  INFORMAL = 'INFORMAL',
  FORMAL = 'FORMAL',
  HUMOROUS = 'HUMOROUS',
  INFORMATIVE = 'INFORMATIVE',
  NOSTALGIC = 'NOSTALGIC',
  PLAYFUL = 'PLAYFUL',
  PROVOCATIVE = 'PROVOCATIVE',
  SMART = 'SMART',
  CREDIBLE = 'CREDIBLE',
}

export type BriefCreative = {
  visualFeatures?: Maybe<Scalars['String']>;
  mandatoryElements?: Maybe<Scalars['String']>;
};

export type BriefComment = {
  userId: Scalars['String'];
  value: Scalars['String'];
  dateTime: Scalars['String'];
};

export type Ads = {
  __typename?: 'Ads';
  items: Array<Ad>;
  paging: Paging;
};

export type Ad = {
  __typename?: 'Ad';
  id: Scalars['String'];
  pgId?: Maybe<Scalars['String']>;

  state: AdState;
  internalState?: Maybe<Scalars['Boolean']>;

  type: AdType;
  title: Scalars['String'];
  versions: Array<AdVersion>;
  history: Array<AdHistoryEvent>;

  deleted?: Maybe<Scalars['Boolean']>;

  createdByUserId?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
};

export enum AdState {
  IN_REVIEW = 'IN_REVIEW',
  EDIT_REQUESTED = 'EDIT_REQUESTED',
  APPROVED = 'APPROVED',
  LAUNCHED = 'LAUNCHED',
  DELETED = 'DELETED',
}

export enum AdType {
  SINGLE_IMAGE = 'SINGLE_IMAGE',
  SINGLE_VIDEO = 'SINGLE_VIDEO',
  CAROUSEL = 'CAROUSEL',
  MULTI_SIZE_IMAGE = 'MULTI_SIZE_IMAGE',
  MULTI_SIZE_VIDEO = 'MULTI_SIZE_VIDEO',
}

export type AdVersion = {
  id: Scalars['String'];
  name: Scalars['String'];
  medias: Array<AdMedia>;
  comments?: Maybe<Array<AdComment>>;
};

export type AdMedia = {
  imageKitFileId?: Maybe<Scalars['String']>;
  imageKitName: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  size: Scalars['Int'];
  mime: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  ratio?: Maybe<Scalars['String']>;
  videoMarkers?: Maybe<Array<AdMediaMerker>>;
  imageCanvas?: Maybe<Scalars['String']>;

  externalUrl?: Maybe<Scalars['String']>;
  externalPermanentUrl?: Maybe<Scalars['String']>;

  // temp processing fields
  isNew?: Maybe<Scalars['Boolean']>;
};

export enum AdMediaType {
  IMAGE = 'image',
  NON_IMAGE = 'non-image',
}

export type AdMediaMarker = {
  commentId: Maybe<Scalars['String']>;
  time: Scalars['Int'];
  text: Scalars['String'];
};

export type AdComment = {
  id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  childrenIds?: Maybe<Array<Scalars['String']>>;
  value: Scalars['String'];
  userId: Scalars['String'];
  internalState?: Maybe<Scalars['Boolean']>;
  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export type AdCommentor = {
  __typename?: 'AdCommentor';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
};

export type AdHistoryEvent = {
  createdByUserId?: Maybe<Scalars['String']>;
  createdByUserName?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  oldState?: Maybe<AdState>;
  newState?: Maybe<AdState>;
  commentAdded?: Maybe<Scalars['Boolean']>;
  commentUpdated?: Maybe<Scalars['Boolean']>;
  commentDeleted?: Maybe<Scalars['Boolean']>;
  internalState?: Maybe<Scalars['Boolean']>;
};

export type ImageKitFileDetails = {
  fileType: Scalars['String'];
  name: Scalars['String'];
  fileId: Scalars['String'];
  filePath: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
  mime: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  originalName?: Maybe<Scalars['String']>;
};

export type AdsDna = {
  __typename?: 'AdsDna';
  items: Array<AdDna>;
  paging: Paging;
};

export type AdDna = {
  id: Scalars['String'];
  clientId: Scalars['String'];
  projectId: Scalars['String'];
  adId: Scalars['String'];

  createdDate: Scalars['String'];

  pgId: Scalars['String'];
  pgIdAuthor?: Maybe<Scalars['String']>;

  // *************** MAIN ***************
  title: Scalars['String'];
  paymentModel: ClientPaymentModel;
  channel?: Maybe<SocialMediaChannel>;
  singleAssetFormatType?: Maybe<AdDnaSingleAssetFormatType>;
  singleAssetType?: Maybe<AdDnaSingleAssetType>;
  singleAssetSubType?: Maybe<AdDnaSingleAssetSubType>;
  languages?: Maybe<Array<Scalars['String']>>;
  layout?: Maybe<AdDnaLayout>;
  assetRatio?: Maybe<Scalars['String']>;
  assetFormat?: Maybe<AdType>;
  slidesNumber?: Maybe<Scalars['Int']>;
  videoLength?: Maybe<Scalars['Int']>;

  // GRAB ATTENTION
  visualHookBranded?: Maybe<Scalars['Boolean']>;
  visualHookFeatures?: Maybe<Array<Scalars['String']>>;
  visualHookUnusual?: Maybe<Scalars['Boolean']>;
  visualHookRelevance?: Maybe<Array<AdDnaVisualHookRelevance>>;
  copyHook?: Maybe<Scalars['String']>;
  copyHookPlain?: Maybe<Scalars['String']>;
  copyHookFraming?: Maybe<Array<AdDnaCopyHookFraming>>;
  copyHookTypology?: Maybe<Array<AdDnaCopyHookTypology>>;
  copyHookRelevance?: Maybe<Array<AdDnaCopyHookRelevance>>;
  popularFormats?: Maybe<Array<AdDnaPopularFormat>>;
  references?: Maybe<Array<AdDnaReferences>>;
  trend?: Maybe<Array<AdDnaTrend>>;
  sound?: Maybe<Array<AdDnaSound>>;
  aiContent?: Maybe<Scalars['Boolean']>;

  // HOLD ATTENTION
  ugcCreators?: Maybe<Array<AdDnaUgcCreators>>;
  visualBranded?: Maybe<Scalars['Boolean']>;
  visualFeatures?: Maybe<Array<Scalars['String']>>;
  copy?: Maybe<Scalars['String']>;
  copyPlain?: Maybe<Scalars['String']>;
  script?: Maybe<Scalars['String']>;
  scriptPlain?: Maybe<Scalars['String']>;
  painPoints?: Maybe<Array<Scalars['String']>>;
  personas?: Maybe<Array<ClientPersona>>;
  benefits?: Maybe<Array<Scalars['String']>>;
  productFeatures?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  valuePropositions?: Maybe<Array<Scalars['String']>>;
  educating?: Maybe<Scalars['Boolean']>;
  educatingApp?: Maybe<Scalars['Boolean']>;
  educatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  educatingAppService?: Maybe<Scalars['Boolean']>;
  educatingBroadTopic?: Maybe<Scalars['Boolean']>;
  userCentricity?: Maybe<Scalars['Int']>;
  specificity?: Maybe<Scalars['Int']>;
  statistics?: Maybe<Scalars['Boolean']>;
  simpleLanguage?: Maybe<Array<AdDnaSimpleLanguage>>;
  legalDisclaimer?: Maybe<Scalars['Boolean']>;
  subtitles?: Maybe<Scalars['Boolean']>;
  entertaining?: Maybe<Scalars['Boolean']>;
  anticipation?: Maybe<Scalars['Boolean']>;

  // EVOKE ACTION
  financialBenefit?: Maybe<Array<AdDnaFinancialBenefit>>;
  instantValue?: Maybe<Array<AdDnaInstantValue>>;
  socialProof?: Maybe<Array<AdDnaSocialProof>>;
  authority?: Maybe<Array<AdDnaAuthority>>;
  lossAversion?: Maybe<Array<AdDnaLossAversion>>;
  scarcity?: Maybe<Array<AdDnaScarcity>>;
  unity?: Maybe<Scalars['Boolean']>;
  deeperMotivationalDesire?: Maybe<Scalars['Int']>;
  sellTheProductSellTheFilling?: Maybe<Scalars['Int']>;
  ctas?: Maybe<Array<Scalars['String']>>;
  ctaPlacement?: Maybe<AdDnaCtaPlacement>;

  // *************** ROLES ***************
  roles?: Maybe<Array<AdDnaRole>>;

  accountManagerUserId?: Maybe<Scalars['String']>;
  accountManagerName?: Maybe<Scalars['String']>;
  accountManagerUserIds?: Maybe<Array<Scalars['String']>>;
  accountManagerNames?: Maybe<Scalars['String']>;

  creativeStrategistUserId?: Maybe<Scalars['String']>;
  creativeStrategistName?: Maybe<Scalars['String']>;
  creativeStrategistUserIds?: Maybe<Array<Scalars['String']>>;
  creativeStrategistNames?: Maybe<Scalars['String']>;

  copywriterUserId?: Maybe<Scalars['String']>;
  copywriterName?: Maybe<Scalars['String']>;
  copywriterUserIds?: Maybe<Array<Scalars['String']>>;
  copywriterNames?: Maybe<Scalars['String']>;

  graphicDesignerUserId?: Maybe<Scalars['String']>;
  graphicDesignerName?: Maybe<Scalars['String']>;
  graphicDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  graphicDesignerNames?: Maybe<Scalars['String']>;

  motionDesignerUserId?: Maybe<Scalars['String']>;
  motionDesignerName?: Maybe<Scalars['String']>;
  motionDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  motionDesignerNames?: Maybe<Scalars['String']>;

  illustratorUserId?: Maybe<Scalars['String']>;
  illustratorName?: Maybe<Scalars['String']>;
  illustratorUserIds?: Maybe<Array<Scalars['String']>>;
  illustratorNames?: Maybe<Scalars['String']>;

  modelUserId?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelUserIds?: Maybe<Array<Scalars['String']>>;
  modelNames?: Maybe<Scalars['String']>;

  photographerUserId?: Maybe<Scalars['String']>;
  photographerName?: Maybe<Scalars['String']>;
  photographerUserIds?: Maybe<Array<Scalars['String']>>;
  photographerNames?: Maybe<Scalars['String']>;

  videographerUserId?: Maybe<Scalars['String']>;
  videographerName?: Maybe<Scalars['String']>;
  videographerUserIds?: Maybe<Array<Scalars['String']>>;
  videographerNames?: Maybe<Scalars['String']>;

  creatorUserId?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  creatorUserIds?: Maybe<Array<Scalars['String']>>;
  creatorNames?: Maybe<Scalars['String']>;

  // *************** SM INSIGHTS ***************
  insightsSpend?: Maybe<Scalars['Float']>;
  insightsAppInstall?: Maybe<Scalars['Int']>;
  insightsAppInstallCost?: Maybe<Scalars['Float']>;
  insightsViewContent?: Maybe<Scalars['Int']>;
  insightsViewContentCost?: Maybe<Scalars['Float']>;
  insightsAddToCart?: Maybe<Scalars['Int']>;
  insightsAddToCartCost?: Maybe<Scalars['Float']>;
  insightsAddToWishlist?: Maybe<Scalars['Int']>;
  insightsAddToWishlistCost?: Maybe<Scalars['Float']>;
  insightsCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsPurchase?: Maybe<Scalars['Int']>;
  insightsPurchaseCost?: Maybe<Scalars['Float']>;

  // META
  insightsMetaSpend?: Maybe<Scalars['Float']>;
  insightsMetaAppInstall?: Maybe<Scalars['Int']>;
  insightsMetaAppInstallCost?: Maybe<Scalars['Float']>;
  insightsMetaViewContent?: Maybe<Scalars['Int']>;
  insightsMetaViewContentCost?: Maybe<Scalars['Float']>;
  insightsMetaAddToCart?: Maybe<Scalars['Int']>;
  insightsMetaAddToCartCost?: Maybe<Scalars['Float']>;
  insightsMetaAddToWishlist?: Maybe<Scalars['Int']>;
  insightsMetaAddToWishlistCost?: Maybe<Scalars['Float']>;
  insightsMetaCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsMetaCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsMetaPurchase?: Maybe<Scalars['Int']>;
  insightsMetaPurchaseCost?: Maybe<Scalars['Float']>;
  insightsMetaReturnOnAdSpend?: Maybe<Scalars['Int']>;
  insightsMetaReturnOnAdSpendCost?: Maybe<Scalars['Float']>;

  // TIKTOK
  insightsTiktokSpend?: Maybe<Scalars['Float']>;
  insightsTiktokAppInstall?: Maybe<Scalars['Int']>;
  insightsTiktokAppInstallCost?: Maybe<Scalars['Float']>;
  insightsTiktokViewContent?: Maybe<Scalars['Int']>;
  insightsTiktokViewContentCost?: Maybe<Scalars['Float']>;
  insightsTiktokAddToCart?: Maybe<Scalars['Int']>;
  insightsTiktokAddToCartCost?: Maybe<Scalars['Float']>;
  insightsTiktokAddToWishlist?: Maybe<Scalars['Int']>;
  insightsTiktokAddToWishlistCost?: Maybe<Scalars['Float']>;
  insightsTiktokCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsTiktokCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsTiktokPurchase?: Maybe<Scalars['Int']>;
  insightsTiktokPurchaseCost?: Maybe<Scalars['Float']>;

  // *************** AD SCORE ***************
  adScore?: Maybe<Scalars['Float']>;
  adScoreMeta?: Maybe<Scalars['Float']>;
  adScoreTiktok?: Maybe<Scalars['Float']>;

  adScoreRegs?: Maybe<Scalars['Float']>;
  adScoreRegsMeta?: Maybe<Scalars['Float']>;
  adScoreRegsTiktok?: Maybe<Scalars['Float']>;

  adScorePurchase?: Maybe<Scalars['Float']>;
  adScorePurchaseMeta?: Maybe<Scalars['Float']>;
  adScorePurchaseTiktok?: Maybe<Scalars['Float']>;
};

export enum AdDnaLayout {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  SQUARE = 'SQUARE',
}

export enum AdDnaPopularFormat {
  POPULAR_FORMATS_HOW_TO_VIDEO = 'POPULAR_FORMATS_HOW_TO_VIDEO',
  POPULAR_FORMATS_FIX_VIDEO = 'POPULAR_FORMATS_FIX_VIDEO',
  POPULAR_FORMATS_SPLIT_SCREEN = 'POPULAR_FORMATS_SPLIT_SCREEN',
  POPULAR_FORMATS_COME_WITH_ME = 'POPULAR_FORMATS_COME_WITH_ME',
  POPULAR_FORMATS_VLOG = 'POPULAR_FORMATS_VLOG',
  POPULAR_FORMATS_PROCESS_VIDEO = 'POPULAR_FORMATS_PROCESS_VIDEO',
  POPULAR_FORMATS_TEXT_ON_SCREEN = 'POPULAR_FORMATS_TEXT_ON_SCREEN',
  POPULAR_FORMATS_TUTORIAL_VIDEO = 'POPULAR_FORMATS_TUTORIAL_VIDEO',
  POPULAR_FORMATS_LIP_SYNC = 'POPULAR_FORMATS_LIP_SYNC',
  POPULAR_FORMATS_TIPS_VIDEO = 'POPULAR_FORMATS_TIPS_VIDEO',
  POPULAR_FORMATS_POV_VIDEOS = 'POPULAR_FORMATS_POV_VIDEOS',
  POPULAR_FORMATS_PRODUCT_REVIEW_VIDEOS = 'POPULAR_FORMATS_PRODUCT_REVIEW_VIDEOS',
  POPULAR_FORMATS_ASMR_VIDEOS = 'POPULAR_FORMATS_ASMR_VIDEOS',
  POPULAR_FORMATS_GREEN_SCREEN_EFFECT = 'POPULAR_FORMATS_GREEN_SCREEN_EFFECT',
  POPULAR_FORMATS_FILTERS_VIDEO = 'POPULAR_FORMATS_FILTERS_VIDEO',
  POPULAR_FORMATS_Q_A = 'POPULAR_FORMATS_Q_A',
}

export enum AdDnaSingleAssetFormatType {
  MOTION = 'MOTION',
  STATIC = 'STATIC',
}

export enum AdDnaSingleAssetType {
  NONE = 'NONE',

  // if video
  MOTION = 'MOTION',
  FILM_PRODUCTION = 'FILM_PRODUCTION',
  UGC = 'UGC',

  // if static
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  CREATOR_FACE = 'CREATOR_FACE',
  ILLUSTRATIONS = 'ILLUSTRATIONS',
  GRAPHIC_DESIGN_BRANDED = 'GRAPHIC_DESIGN_BRANDED',
  MEME = 'MEME',
  DMS = 'DMS',
  IOS = 'IOS',

  // todo remove later, obsolete
  THIRD_PERSON_POV = 'THIRD_PERSON_POV',
  CREATOR = 'CREATOR',
  VIDEO_PRODUCTION = 'VIDEO_PRODUCTION',
  GRAPHIC_DESIGN = 'GRAPHIC_DESIGN',
  CP = 'CP',
  ILLUSTRATION = 'ILLUSTRATION',
  LIVE_ACTION = 'LIVE_ACTION',
}

export enum AdDnaSingleAssetSubType {
  NONE = 'NONE',

  // motion
  TWO_D = 'TWO_D',
  THREE_D = 'THREE_D',
  BOTH = 'BOTH',

  // ugc
  FACING_THE_CAMERA = 'FACING_THE_CAMERA',
  FIRST_PERSON_SHOOTER_POV = 'FIRST_PERSON_SHOOTER_POV',
  THIRD_PERSON_POV = 'THIRD_PERSON_POV',
  MIXED_CAMERA_VIEWS = 'MIXED_CAMERA_VIEWS',

  // photography
  STILL_IMAGE = 'STILL_IMAGE',
  PORTRAITS = 'PORTRAITS',

  // graphic design
  REVIEWS = 'REVIEWS',

  // DMs
  FB = 'FB',
  IMESSAGE = 'IMESSAGE',
  INSTAGRAM = 'INSTAGRAM',
  SNAPCHAT = 'SNAPCHAT',
  TWITCH = 'TWITCH',

  // iOS
  CALENDAR = 'CALENDAR',
  NOTES = 'NOTES',
  REMINDER = 'REMINDER',
  NOTIFICATION = 'NOTIFICATION',

  // todo remove later, obsolete
  CREATOR = 'CREATOR',
  MEME = 'MEME',
  APPS = 'APPS',
  IOS_APPS = 'IOS_APPS',
  COMIC = 'COMIC',
  DRAWING = 'DRAWING',
  PORTRAIT = 'PORTRAIT',
  TWO_D_THREE_D = 'TWO_D_THREE_D',
}

export enum AdDnaVisualHookRelevance {
  PAIN_POINT = 'PAIN_POINT',
  PERSONA = 'PERSONA',
  BENEFIT = 'BENEFIT',
}

export enum AdDnaCopyHookFraming {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export enum AdDnaCopyHookTypology {
  QUESTION = 'QUESTION',
  STATEMENT = 'STATEMENT',
}

export enum AdDnaCopyHookRelevance {
  PAIN_POINT = 'PAIN_POINT',
  PERSONA = 'PERSONA',
  BENEFIT = 'BENEFIT',
}

export enum AdDnaUgcCreators {
  ONE = 'ONE',
  MORE_THAN_ONE = 'MORE_THAN_ONE',
}

export enum AdDnaSimpleLanguage {
  SIMPLE_LANGUAGE = 'SIMPLE_LANGUAGE',
  NO_JARGON = 'NO_JARGON',
  MINIMAL_JARGON = 'MINIMAL_JARGON',
  YES_JARGON = 'YES_JARGON',
}

export enum AdDnaSocialProof {
  ACTED_TESTIMONIAL = 'ACTED_TESTIMONIAL',
  TESTIMONIAL = 'TESTIMONIAL',
  REVIEWS = 'REVIEWS',
  CELEBRITY_ENDORSEMENT = 'CELEBRITY_ENDORSEMENT',
  NONE = 'NONE',
}

export enum AdDnaAuthority {
  EXPERT = 'EXPERT',
  ADMIRABLE_PROFESSION = 'ADMIRABLE_PROFESSION',
  CELEBRITY = 'CELEBRITY',
}

export enum AdDnaScarcity {
  LIMITED_TIME_OFFERING = 'LIMITED_TIME_OFFERING',
  QUANTITY_LIMITED_OFFERING = 'QUANTITY_LIMITED_OFFERING',
  EXCLUSIVE_ACCESS = 'EXCLUSIVE_ACCESS',
  DEMAND_EMPHASIS = 'DEMAND_EMPHASIS',
  OTHER = 'OTHER',
}

export enum AdDnaInstantValue {
  INSTANT_LEARNING = 'INSTANT_LEARNING',
  OTHER = 'OTHER',
}

export enum AdDnaLossAversion {
  FOCUS_ON_LOSS = 'FOCUS_ON_LOSS',
  FOCUS_ON_GAIN = 'FOCUS_ON_GAIN',
}

export enum AdDnaTrend {
  PERSON = 'PERSON',
  TOPIC = 'TOPIC',
  SONG = 'SONG',
  EVENT = 'EVENT',
  MEME = 'MEME',
  FORMAT = 'FORMAT',
  TIKTOK = 'TIKTOK',
}

export enum AdDnaReferences {
  FAMOUS_PEOPLE = 'FAMOUS_PEOPLE',
  BRANDS = 'BRANDS',
}

export enum AdDnaSound {
  MUSIC = 'MUSIC',
  TIKTOK_VOICE = 'TIKTOK_VOICE',
  HUMAN_VOICE_COVER = 'HUMAN_VOICE_COVER',
  ASMR = 'ASMR',
}

export enum AdDnaFinancialBenefit {
  PROMO_CODE = 'PROMO_CODE',
  DISCOUNT = 'DISCOUNT',
  OFFER = 'OFFER',
  BENEFIT = 'BENEFIT',
}

export enum AdDnaVisualFeature {
  PAINTGUN_LOGO = 'PAINTGUN_LOGO',
  APP_SCREEN = 'APP_SCREEN',
  PRODUCT = 'PRODUCT',
  BANK_CARD = 'BANK_CARD',
  MONEY = 'MONEY',
  AR = 'AR',
  EMOJIS = 'EMOJIS',
  APP_STORES = 'APP_STORES',
}

export type AdDnaRole = {
  role: AdDnaRoleType;
  userId: Scalars['String'];
};

export enum AdDnaRoleType {
  NONE = 'NONE',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  CREATIVE_STRATEGIST = 'CREATIVE_STRATEGIST',
  COPYWRITER = 'COPYWRITER',
  GRAPHIC_DESIGNER = 'GRAPHIC_DESIGNER',
  MOTION_DESIGNER = 'MOTION_DESIGNER',
  ILLUSTRATOR = 'ILLUSTRATOR',
  MODEL = 'MODEL',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  VIDEOGRAPHER = 'VIDEOGRAPHER',
  CREATOR = 'CREATOR',
}

export enum AdDnaCtaPlacement {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  MIDDLE_LEFT = 'MIDDLE_LEFT',
  MIDDLE_CENTER = 'MIDDLE_CENTER',
  MIDDLE_RIGHT = 'MIDDLE_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export type AdDnaInsightsAction = {
  type: Scalars['String'];
  value: Scalars['String'];
};

export type Analytics = {
  platformDetails: AnalyticsPlatformDetails;
  platformDetailsMeta: AnalyticsPlatformDetails;
  platformDetailsTiktok: AnalyticsPlatformDetails;

  topProjectsByAdSpend: Array<AnalyticsProjectDetails>;
  topProjectsByAdSpendMeta: Array<AnalyticsProjectDetails>;
  topProjectsByAdSpendTiktok: Array<AnalyticsProjectDetails>;

  // *** top ads by ad score for X ***

  topAdsByAdScore: Array<AnalyticsAdDetails>;
  topAdsByAdScoreMeta: Array<AnalyticsAdDetails>;
  topAdsByAdScoreTiktok: Array<AnalyticsAdDetails>;

  topAdsByAdScoreRegs: Array<AnalyticsAdDetails>;
  topAdsByAdScoreRegsMeta: Array<AnalyticsAdDetails>;
  topAdsByAdScoreRegsTiktok: Array<AnalyticsAdDetails>;

  topAdsByAdScorePurchase: Array<AnalyticsAdDetails>;
  topAdsByAdScorePurchaseMeta: Array<AnalyticsAdDetails>;
  topAdsByAdScorePurchaseTiktok: Array<AnalyticsAdDetails>;

  // *** average ad score for X by product feature ***

  averageAdScoreByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageAdScoreByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageAdScoreByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  averageAdScoreRegsByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageAdScoreRegsByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageAdScoreRegsByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  averageAdScorePurchaseByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageAdScorePurchaseByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageAdScorePurchaseByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  // *** average X by product feature ***

  averageInstallsByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageInstallsByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageInstallsByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  averageRegsByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageRegsByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageRegsByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  averagePurchaseByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averagePurchaseByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averagePurchaseByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  // *** average cost per X by product feature ***

  averageCostPerInstallByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageCostPerInstallByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageCostPerInstallByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  averageCostPerRegByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageCostPerRegByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageCostPerRegByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;

  averageCostPerPurchaseByProductFeature: Array<AnalyticsProductFeatureDetails>;
  averageCostPerPurchaseByProductFeatureMeta: Array<AnalyticsProductFeatureDetails>;
  averageCostPerPurchaseByProductFeatureTiktok: Array<AnalyticsProductFeatureDetails>;
};

export type AnalyticsAdDetails = {
  pgId: Scalars['String'];
  installs?: Maybe<Scalars['Int']>;
  costPerInstall?: Maybe<Scalars['Float']>;
  regs?: Maybe<Scalars['Int']>;
  costPerReg?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Int']>;
  costPerPurchase?: Maybe<Scalars['Float']>;
  adScore?: Maybe<Scalars['Float']>;
};

export type AnalyticsPlatformDetails = {
  averageAdScore: Scalars['Float'];
  averageAdScoreRegs?: Scalars['Float'];
  averageAdScorePurchase?: Maybe<Scalars['Float']>;
  adsCount: Scalars['Int'];
  ugcAdsCount: Scalars['Int'];
  nonUgcAdsCount: Scalars['Int'];
};

export type AnalyticsProjectDetails = {
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  adSpend: Scalars['Float'];
};

export type AnalyticsProductFeatureDetails = {
  productFeature: Scalars['String'];
  adScore?: Maybe<Scalars['Float']>;
  installs?: Maybe<Scalars['Float']>;
  costPerInstall?: Maybe<Scalars['Float']>;
  regs?: Maybe<Scalars['Float']>;
  costPerReg?: Maybe<Scalars['Float']>;
  purchase?: Maybe<Scalars['Float']>;
  costPerPurchase?: Maybe<Scalars['Float']>;
};

export enum AdEvent {
  /**
   * app_install & cost_per_app_install, skan_app_install & skan_cost_per_app_install
   */
  APP_INSTALL = 'APP_INSTALL',

  /**
   * view_content, skan_view_content
   */
  VIEW_CONTENT = 'VIEW_CONTENT',

  /**
   * app_event_add_to_cart, skan_add_to_cart
   */
  ADD_TO_CART = 'ADD_TO_CART',

  /**
   * add_to_wishlist, skan_add_to_wishlist
   */
  ADD_TO_WISHLIST = 'ADD_TO_WISHLIST',

  /**
   * registration, skan_registration
   */
  REGISTRATION = 'REGISTRATION',

  /**
   * purchase & cost_per_purchase, skan_purchase & skan_cost_per_purchase, purchase_roas
   */
  PURCHASE = 'PURCHASE',

  /**
   * return_on_ad_spend
   */
  RETURN_ON_AD_SPEND = 'RETURN_ON_AD_SPEND',
}
